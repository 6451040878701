const store = new Vuex.Store({
	state: {
		header: {
			index: 0
		}
	},
	mutations: {
		SET_HEADER_INDEX(state, value = 0) {
			state.header.index = value;
		}
	}
});